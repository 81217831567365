
<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" @open="open">
      <div slot="title">
        <div class="title_Dialog">我的分配</div>
      </div>
      <div style="display: flex; align-items: center; flex-wrap: wrap">
        <div>
          <span class="seach_text">姓名：</span>
          <el-input
            placeholder="请输入姓名"
            v-model="formInline.f_like_userName"
            size="mini"
            style="margin-right: 10px; width: 150px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <div>
          <span class="seach_text">用工企业名称：</span>
          <el-select
            remote
            reserve-keyword
            :remote-method="query"
            @change="getCity"
            v-model="formInline.workEnterpriseCode"
            clearable
            filterable
            placeholder="请输入企业名称"
            size="mini"
            style="margin-right: 10px"
          >
            <el-option v-for="item in eList" :key="item.code" :label="item.name" :value="item.code"></el-option>
          </el-select>
        </div>
        <div>
          <span class="seach_text">企业区域：</span>
          <el-select
            v-model="formInline.workEnterpriseAreaCode"
            @change="querycity"
            style="margin-right: 10px; width: 200px"
            filterable
            clearable
            size="mini"
            :placeholder="'请输入企业区域'"
          >
            <el-option-group
              v-for="group in areaSelectList"
              :key="group.provinceName"
              :label="group.provinceName"
            >
              <span style="color: red" slot="label">
                {{
                group.provinceName
                }}
              </span>
              <el-option
                v-for="item in group.citys"
                :key="item.code"
                :label="item.cityName"
                :value="item.code"
              ></el-option>
            </el-option-group>
          </el-select>
        </div>

        <div>
          <span class="seach_text">企业门店：</span>
          <el-select
            remote
            reserve-keyword
            v-model="formInline.workEnterpriseStoreCode"
            clearable
            filterable
            placeholder="请输入企业门店"
            size="mini"
            style="margin-right: 10px"
          >
            <el-option
              v-for="item in storeList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </div>
        <el-button
          size="small"
          style="margin: 10px; border-radius: 5px"
          type="primary"
          @click="seach"
        >查询</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="员工姓名">
          <template slot-scope="scope">
            <span v-if="scope.row.user">{{ scope.row.user.name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="手机号">
          <template slot-scope="scope">
            <div v-if="scope.row.user" class="clickColor">
              {{
              scope.row.user.username ||''
              }}
            </div>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="用工企业">
          <template slot-scope="scope">
            <span v-if="scope.row.workEnterprise">{{ scope.row.workEnterprise.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="企业区域">
          <template slot-scope="scope">
            <span
              v-if="scope.row.area"
            >{{ scope.row.area.provinceName }}-{{scope.row.area.cityName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="企业门店">
          <template slot-scope="scope">
            <span v-if="scope.row.store">{{ scope.row.store.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="getAllocated(scope.row)">重新分配</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <span class="clickColor">共{{ total }}条</span>
        <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
        <el-pagination
          background
          :current-page.sync="pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="prev,pager, next,jumper"
          :total="total"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const staffDispatchList = api()('staff.dispatch.list.json')
export const workEnterpriseList = api()('work_enterprise.list.json')
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json')
export const workEnterpriseStoreList = api()('/work_enterprise_store.list.json')
export const staffDelete = api()('staff.delete.json')
export default {
  props: {
    dialogVisible: Boolean
  },
  data () {
    return {
      tableData: [],
      eList: [],
      storeList: [],
      areaSelectList: [],
      formInline: {
        f_like_userName: '',
        workEnterpriseCode: '',
        workEnterpriseAreaCode: '',
        workEnterpriseStoreCode: '',
      },
      pageNumber: 1,
      pageSize: 10,
      total: 0
    }
  },
  methods: {
    handleClose () {
      this.closeDialog()
    },

    closeDialog () {
      this.formInline.f_like_userName = '',
        this.formInline.workEnterpriseCode = '',
        this.formInline.workEnterpriseAreaCode = '',
        this.formInline.workEnterpriseStoreCode = '',
        this.$emit('update:dialogVisible', false);
      this.$emit('func', this.dialogVisible);
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.loadData()
    },
    handleCurrentChange (val) {
      this.pageNumber = val
      this.loadData()
    },
    loadData () {
      const params = {
        ...this.formInline,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
      staffDispatchList(params).then((res) => {
        this.tableData = res.list
        this.total = res.total
        console.log(this.tableData);
      })
    },
    getAllocated (row) {
      const params = {
        code: row.user.code
      }
      staffDelete(params).then(() => {
        this.pageNumber = 1
        this.loadData()
      })
    },
    enterpriseList (val) {
      const params = {
        keywords: val,
      }
      workEnterpriseList(params).then((res) => {
        this.eList = res.list
      })
    },
    query (val) {
      this.pageNumber = 1
      this.enterpriseList(val)
    },
    getCity (val) {
      if (val) {
        const params = {
          workEnterpriseCode: val
        };
        workEnterpriseAreaContainArea(params).then(res => {
          this.areaSelectList = res
        });
      }
      else {
        this.areaSelectList = []
        this.storeList = []
        this.formInline.workEnterpriseAreaCode = ''


        this.formInline.workEnterpriseStoreCode = ''
      }
    },
    querycity (val) {
      if (val) {
        workEnterpriseStoreList({ workEnterpriseAreaCode: val }).then((res) => {
          this.storeList = res.list
        })
      }
      else {
        this.formInline.workEnterpriseStoreCode = ''
        this.storeList = []
      }

    },
    seach () {
      this.pageNumber = 1
      this.loadData()
    },
    open () {
      this.loadData()
      this.enterpriseList()
    }
  },
}
</script>
<style lang="scss" scoped>
.title_Dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>